// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    padding: 20px;
    padding-top: 80px;
    transition: padding-top 0.3s;
}

@media (max-width: 991.98px) {
    .content {
        padding-top: 100px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,4BAA4B;AAChC;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".content {\n    padding: 20px;\n    padding-top: 80px;\n    transition: padding-top 0.3s;\n}\n\n@media (max-width: 991.98px) {\n    .content {\n        padding-top: 100px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

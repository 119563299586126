import React from 'react';
import { Card, Col } from 'react-bootstrap';

const LogEntry = ({ timestamp, username, action }) => {
    return (
        <Col md={4} sm={6} xs={12} className="mb-4">
            <Card>
                <Card.Body>
                <Card.Title>{username}</Card.Title>
                <Card.Text>
                    Time: {timestamp}
                </Card.Text>
                <Card.Text>
                    {action}
                </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default LogEntry;

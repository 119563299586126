import React from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import trophyImage from '../images/trophies.png';
import medalImage from '../images/medals.png'
import warTrophyImage from '../images/warTrophies.png';
import membersImage from '../images/members.png';
import './ClanPanel.css';
import { addReactLineBreaks } from '../util/StringHelp.js'
import { Link } from 'react-router-dom';

const ClanPanel = ({ clanTag, clanName, leader, logo, trophies, requiredTrophies, members, warTrophies, minWeeklyWarMedals, playersNeeded, additionalInfo }) => {
    return (
        //<Col md={3} sm={6} xs={6} className="mb-4">
        <Col md={3} sm={6} xs={12} className="mb-4">
            <Card>
                <Card.Img variant="top" src={logo} />
                <Card.Body>
                    <Card.Title>{clanName}</Card.Title>
            		<Card.Text>
            			Leader: {leader}
            			<br />
            			<img src={membersImage} className='text-icon' alt='Members Icon' /> Members: {members}/50
            			<br />
            			<img src={trophyImage} className='text-icon' alt='Tropy Icon' /> Trophies: {trophies}
            			<br />
            			<img src={warTrophyImage} className='text-icon' alt='War Trophies Icon' /> War Trophies: {warTrophies}
            		</Card.Text>
                    <Card.Text>
                        Players needed: {playersNeeded}
            		    <br />
            		    Required Trophies <img className='text-icon' src={trophyImage} alt='Trophy icon'></img>: {requiredTrophies}
            		    <br />
            		    Required Weekly Medals <img className='text-icon' src={medalImage} alt='Medal icon'></img>: {minWeeklyWarMedals}
            		    <br />
            		    <br />
            		    Additional information:
            		    <br />
            		    {addReactLineBreaks(additionalInfo)}
                    </Card.Text>
                    <Button variant="light" className="w-100 mt-3 btn-outline-dark" as={Link} to={`/edit-clan/${clanTag}`}>
                        Edit Clan
                    </Button>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default ClanPanel;

import React from 'react';

export const addReactLineBreaks = (text) => {
    return typeof(text) === 'undefined' ? text : text.split('\\n').map((line, index, array) => (
        <React.Fragment key={index}>
            {line}
            {index < array.length - 1 && <br />}
        </React.Fragment>
    ));
};

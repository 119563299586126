import React from 'react';
import LogEntry from '../components/LogEntry';
import './Log.css';

const Log = () => {
    const logEntries = [
        { timestamp: '2024-08-08 11:10:52 am UTC+2', username: 'dape.', action: 'Raised needed tickets for Bleesed Worthy to 2'  },
        { timestamp: '2024-08-08 11:11:17 am UTC+2', username: 'dape.', action: 'Raised needed tickets for Bleesed Worthy to 2'  },
        { timestamp: '2024-08-08 11:11:37 am UTC+2', username: 'Schutz', action: 'Updated Requirements for Blessed Worthy (7500 -> 8000 Trophies)'  },
        { timestamp: '2024-08-08 11:10:52 am UTC+2', username: 'Shultz', action: 'Updated Requirements for Blessed Worthy (2000 -> 2200 War Medals)'  }
    ];

    return (
        <div>
            <h1 className="my-4">Log</h1>
            <div class='log-container'>
                {logEntries.map((entry, index) => (
                <LogEntry
                    key={index}
                    timestamp={entry.timestamp}
                    username={entry.username}
                    action={entry.action}
                />
                ))}
            </div>
        </div>
    );
};

export default Log;
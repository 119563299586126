// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-icon {
	vertical-align: middle;
	width: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/components/ClanPanel.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,UAAU;AACX","sourcesContent":[".text-icon {\n\tvertical-align: middle;\n\twidth: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const EditClan = () => {
    const navigate = useNavigate();
    const { clanTag } = useParams();
    const [clan, setClan] = useState(null);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        fetch(`/api/clans/${clanTag}`).then(
            response => {
                if (!response.ok) {
                    throw new Error(`HTTP Error | Status: ${response.status}`);
                }
                return response.json();
            }
        ).then(
            data => {
                setClan(data);
            }
        ).catch(reason => console.log(reason));
    }, [clanTag]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClan({
            ...clan,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);
        fetch(`/api/clans/${clanTag}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(clan),
        }).then(response => {
            if (!response.ok) {
                throw new Error(`HTTP Error | Status: ${response.status}`);
            }
            return response.json();
        }).then(data => {
            console.log("Clan updated:", data);
            navigate('/');
        }).catch(reason => console.log(reason)
        ).finally(() => setProcessing(false));

    };

    if (!clan) {
        return <p>Loading clan...</p>;
    }

    return (
        <Container>
            <h1 className="my-4">{clan.clanName} [#{clan.clanTag}]</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="inputMinWeeklyWarMedals">Min. weekly war medals</label>
                    <input type="number" className="form-control" id="inputMinWeeklyWarMedals" name="minWeeklyWarMedals" aria-describedby="minWeeklyWarMedalsHelp" placeholder={clan.minWeeklyWarMedals} value={clan.minWeeklyWarMedals} onChange={handleInputChange} />
                    <small id="minWeeklyWarMedalsHelp" className="form-text text-muted">The amount of weekly war medals the members should aim for.</small>
                </div>
                <br />
                <div className="form-group">
                    <label htmlFor="inputPlayersNeeded">Players needed</label>
                    <input type="number" className="form-control" id="inputPlayersNeeded" aria-describedby="playersNeededHelp" name="playersNeeded" placeholder={clan.playersNeeded} value={clan.playersNeeded} onChange={handleInputChange} />
                    <small id="playersNeededHelp" className="form-text text-muted">The amount of needed players (tickets).</small>
                </div>
                <br />
                <div className="form-group">
                    <label htmlFor="inputAdditionalInfo">Additional information</label>
                    <textarea defaultValue={clan.additionalInfo} className="form-control" id="inputAdditionalInfo" rows="5" name="additionalInfo" onChange={handleInputChange}></textarea>
                </div>
                <button type="submit" className="btn btn-primary" disabled={processing}>
                    {processing ? 'Loading...' : 'Submit'}
                    {processing && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" visible="{processing}"></span>)}
                </button>
            </form>
        </Container>
    );
};

export default EditClan;


import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const MainNavbar = () => {
    const location = useLocation();
    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar expand="lg" bg="dark" variant="dark" expanded={expanded} fixed="top" className="navbar">
            <Navbar.Brand as={Link} to="/">War Seekers CR Clan-Management</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/" active={location.pathname === '/'} onClick={() => setExpanded(false)}>
                        Home
                    </Nav.Link>
                    <Nav.Link as={Link} to="/log" active={location.pathname === '/log'} onClick={() => setExpanded(false)}>
                        Log
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default MainNavbar;

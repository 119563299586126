// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.navbar {
    height: max-content;
}

.navbar-collapse {
    justify-content: center;
}

.nav-link.active {
    font-weight: bold;
    color: #fff;
}

@media (max-width: 991.98px) {
    .navbar {
        flex-direction: row;
    }
    .navbar-collapse {
        justify-content: flex-start;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,MAAM;IACN,OAAO;IACP,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":[".navbar {\n    width: 100%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 1000;\n    display: flex;\n    flex-direction: column;\n}\n\n.navbar {\n    height: max-content;\n}\n\n.navbar-collapse {\n    justify-content: center;\n}\n\n.nav-link.active {\n    font-weight: bold;\n    color: #fff;\n}\n\n@media (max-width: 991.98px) {\n    .navbar {\n        flex-direction: row;\n    }\n    .navbar-collapse {\n        justify-content: flex-start;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainNavbar from './components/Navbar';
import Home from './pages/Home';
import Log from './pages/Log'
import EditClan from './pages/EditClan'
import { Container } from 'react-bootstrap';
import './App.css';

const App = () => {
    return (
        <Router>
            <div>
                <MainNavbar />
                <Container className="content">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/log" element={<Log />} />
                        <Route path="/edit-clan/:clanTag" element={<EditClan />} />
                    </Routes>
                </Container>
            </div>
        </Router>
    );
};

export default App;

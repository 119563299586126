import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import ClanPanel from '../components/ClanPanel';

const Home = () => {

	const [clans, setClans] = useState([]);

    useEffect(() => {
        fetch("/api/clans").then(
            response => {
                if (!response.ok) {
                    throw new Error(`HTTP Error | Status: ${response.status}`);
                }
                return response.json();
            }
        ).then(
            data => {
                setClans(data);
            }
        ).catch(reason => console.log(reason));
    }, []);


    return (
        <Container>
            <h1 className="my-4">Clan Overview</h1>
			{clans.length ? (
				<Row>
					{clans.map((clan, index) => (
						<ClanPanel
							key={clan.clanTag}
							clanTag={clan.clanTag}
							clanName={clan.clanName}
							leader={clan.leader}
							logo={clan.logo}
							trophies={clan.trophies}
							requiredTrophies={clan.requiredTrophies}
							members={clan.members}
							warTrophies={clan.warTrophies}
							minWeeklyWarMedals={clan.minWeeklyWarMedals}
							playersNeeded={clan.playersNeeded}
							additionalInfo={clan.additionalInfo}
						/>
					))}
				</Row>
            ) : (
                <p>Loading Clans...</p> 
            )}
        </Container>
    );
};

export default Home;

